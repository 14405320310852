#contactPage{
    width: 100vw;
    max-width: 60rem;
    margin: 1rem auto;
    margin-top: 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sayhi{
    font-size: 400px;
    color: rgba(0, 0, 0, 0.132);
    position: absolute;
    z-index: -1;
}


.contactDesc{
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}
.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(23, 23, 23);
}

.submitBtn{
    background: white;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}

.links{
    display: flex;
}
.link{
    object-fit: cover;
    height: 3rem;
    margin: 0 0.75rem;
}
h1{
    font-size: 50px;
}