*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}
body{
    background-size: cover;
    background-color: rgb(255, 255, 255);
}
.btn{
    width: 160px;
    background: rgb(255, 0, 0);
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    color: white;
}
