#intro{
    width: 100vw;
    max-width: 80rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.introContent{
    height: 90vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.btn_into {
    text-decoration: none;
    color: inherit;
    display: flex;
    gap: 2rem;
}

.btn_into>a{
    text-decoration: none;
}
.hello{
    font-size: 1.75rem;
    font-weight: 100;
}
.intotext{
    font-size: 2rem;
    padding: 1rem;
}
.intorname{
    color: red;
    font-size: 3rem;
}

.intropar{
    font-size: 1.1rem;
    font-weight: 100;
    text-wrap: balance;
}

/* .btn{
    width: 160px;
    background: #618EA8ff;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
} */
/* .btnimg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
} */

.skillsImg{
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: absolute;
    top: 90%;
    right: 30%;
    justify-content: space-between;
    border: none;
    cursor: pointer;
}
.skillsImg :hover{
    transform: scale(1.7);
}

@media screen and (max-width:840px) {
    .aboutimg {
        display: none;
    }
    .skillsImg {
        display: none;
    }
    .intropar {
        margin-left: 1rem;
    }

}
