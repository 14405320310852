
.Updates{
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; 
    margin: 0 auto;
    margin-top: 4rem;
    position: relative;
    border: none;
    
} 

.Az-Card{
    width: 400px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.3s;
    animation: ease-in-out;
    border-radius: 7px;
    position: relative;
    justify-content: space-between;

}
.Az-Card:hover{
    transform: scale(1.);
    box-shadow: 0px 0px 15px 0px;
}
.img-container img{
    overflow: hidden;
    height: 200px;
    margin-top: 1rem;
}

.card-content{
    margin: 1rem;
    margin-top: 0.5rem;
}
h3,p{
    margin: 0;
    padding: 0;
}
/* p{
    max-width: 350px;
} */
.card-title{
    margin-bottom: 0.5rem;
}
/* .butn{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

} */
/* .butn button{
    padding: 0.5rem;
    background-color: white;
    border: none;
    transition: 0.2s;
    margin-bottom: 0.rem;
    border-radius: 3px;
} */
/* .butn button:hover{
    background: rgba(27, 156, 252, 0.1);
} */
.card-body a{
    text-transform: uppercase;
    color: #1b9cfc;
    text-decoration: none;
    font-weight: bold;
}

.loader{
  position: absolute;
  top: 50%;
  left: 30%;
}

.glitch {
    position: relative;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    letter-spacing: 5px;
    z-index: 1;
    animation: shift 1s ease-in-out infinite alternate;
  }
  
  .glitch:before,
  .glitch:after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
  }
  
  .glitch:before {
    animation: glitch 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
    color: #8b00ff;
    z-index: -1;
  }
  
  .glitch:after {
    animation: glitch 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
    color: #00e571;
    z-index: -2;
  }
  
  @keyframes glitch {
    0% {
      transform: translate(0);
    }
  
    20% {
      transform: translate(-3px, 3px);
    }
  
    40% {
      transform: translate(-3px, -3px);
    }
  
    60% {
      transform: translate(3px, 3px);
    }
  
    80% {
      transform: translate(3px, -3px);
    }
  
    to {
      transform: translate(0);
    }
  }
  
  @keyframes shift {
    0%, 40%, 44%, 58%, 61%, 65%, 69%, 73%, 100% {
      transform: skewX(0deg);
    }
  
    41% {
      transform: skewX(10deg);
    }
  
    42% {
      transform: skewX(-10deg);
    }
  
    59% {
      transform: skewX(40deg) skewY(10deg);
    }
  
    60% {
      transform: skewX(-40deg) skewY(-10deg);
    }
  
    63% {
      transform: skewX(10deg) skewY(-5deg);
    }
  
    70% {
      transform: skewX(-50deg) skewY(-20deg);
    }
  
    71% {
      transform: skewX(10deg) skewY(-10deg);
    }
  }
  

  @media screen and (max-width:720px){
    .Updates{
      width: 90vw;
  }


  }

