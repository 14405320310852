.Ac_container{
    width: 100vw;
    height: 80vh;
    max-width: 80rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-evenly;
}
.Ac_AccordionSection{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    align-self: flex-end;
}
.AccordionSection{
    

}
.wrap{
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    border: solid 1px black;
    padding: 0.9rem;
    width: 80%;
    
}
.wrap>h1{
    font-size: 1rem;
    font-weight: 500;
}
.dropDown{
    background: rgba(255, 255, 255, 0.589);
    color: black;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid rgb(0, 0, 0);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    align-items: center;
}

.icon_fi{
    width: 2rem;
    height: 2rem;
    color: red;
}


@media screen and (max-width:840px){
    .wrap{
        width: 90%;
        margin: 0 auto;
        font-size: 16px;
        overflow-y: hidden;
    }
    .Ac_container{
        align-items: center;
    }
    .faq_header h1{
        font-size: 20px;
    }
}