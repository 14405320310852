footer{
    max-width: 80rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    background: transparent;
    margin-top: 1rem;
    padding-top: 2rem;
    font-size: 0.9rem;
    color: black;
    text-decoration: none;

}

.footer__container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr ;
    gap: 6rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container article p {
    margin-top: 0.5rem;
}
.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}
.footer__socials {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer__socials a {
    background: gray;
    padding: 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    /* transition: var(--transition); */
}
.footer__socials a svg {
    color: black;
    font-size: 20px;
}
.footer__socials a:hover{
    border-color: rgb(255, 0, 0);
    background: transparent;
}
.footer__copyright{
    color: black;
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid black ;
    margin-top: 4rem;
}
.logo{
    font-size: ;
}


@media screen and (max-width: 1024px) {
    .footer__container {
        display: flex;
        flex-wrap: wrap;
        padding-left: 1rem;
    }
}