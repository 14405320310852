nav{
    height: 5rem;
    width: 100vw;
    max-width: 80rem;
    background: transparent;
    place-items: center;
    position: static;
    top: 0;
    right: 0;
    margin: 1rem auto;
    z-index: 99;
}

/* only shows on small screens */
.nav__toggle-btn{
    display: none;
}
.nav_container{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10rem;
    align-items: center;
    position: relative;
}
.logo{
    width: 10rem;
    color: black;
    font-size: 40px;
}
.logo span{
    color: red;
    font-size: 40px;
}
.nav__links{
    display: flex;
    gap: 3.5rem;
    align-items: center;
}
.nav__links a{
    transition: var(--transition);
    color: black;

}
.nav__links a:hover {
    color: red;
}

.active-nav{
    position: relative;
}
.active-nav::after{
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    background: rgb(255, 0, 0);
    position: absolute;
    left: calc(50% - 0.4rem);
    transform: rotate(45deg);
    margin-top: 1.5rem;
}


/* Media Queries for small screen */

@media screen and (max-width :1024px) {
 
    .nav__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }
    .nav__toggle-btn svg{
        color: rgb(255, 0, 0);
        border: none;
    }
    .nav__btn{
        display: none;
    }

    .nav__links{
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
    }

    .active-nav, .active-nav::after{
        display: none;
    }
    .nav__links li {
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);
    }
    .nav__links li a {
        background-color: rgb(216, 226, 229);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }
    .show_nav{
        display: flex;
    }
    .hide_nav{
        display: none;
    }

    /* go back back to 1:10:00 for nav animation EGATOR */
}

