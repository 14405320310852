.card {
    --main-color: #000;
    --submain-color: #78858F;
    --bg-color: #fff;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    width: 300px;
    height: 384px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: var(--bg-color);
  }
  
  .card__img {
    height: 192px;
    width: 100%;
  }
  
  .card__img img{
    height: 90%;
    width: 100%;
    transition: all 0.3s;
  }
  .card__img img:hover{
    transform: scale(1.01);
  }
  .card__title {
    margin-top: 10px;
    font-weight: 500;
    font-size: 18px;
    color: var(--main-color);
  }
  
  .card__subtitle {
    margin-top: 10px;
    font-weight: 400;
    font-size: 15px;
    color: var(--submain-color);
  }
  
  .card__btn {
    margin-top: 15px;
    width: 76px;
    height: 31px;
    border: 2px solid var(--main-color);
    border-radius: 4px;
    font-weight: 700;
    font-size: 11px;
    color: var(--main-color);
    background: white;
    text-transform: uppercase;
    transition: all 0.3s;
    cursor: pointer;
  }
  
  .card__btn-solid {
    background: var(--main-color);
    color: var(--bg-color);
  }
  
  .card__btn:hover {
    background: rgb(2, 171, 255);
    color: var(--bg-color);
  }
  
  .card__btn-solid:hover {
    background: var(--bg-color);
    color: var(--main-color);
  }
  .card__wrapper{
    display: flex;
    gap: 1rem;
  }



  .pp_container{
    idth: 100vw;
    max-width: 80rem;
    margin: 3rem auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  .pp_container_right{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    gap: 1rem;
    width: 50%;
  }
  .pp_container_left{
    display: flex;
    flex-direction:row;
    align-items:flex-start;
    gap: 1rem;
    width: 50%;
  }

  .pp_container_right > p > span{
    font-size: 22px;
    font-weight: 300;
  }