.comingsoon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 5rem;
}

.comingsoon h1 {
    font-size: 100px;
    font-weight: 600;
    margin: 1rem;
    color: red;
}